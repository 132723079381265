import React, { useMemo, useRef } from 'react';
import { InputArea } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { updateJustification, selectAttemptId } from 'actions/studentAttemptActions';
import { selectUserLoggedIn } from 'actions/userActions';
import api from 'api';
import { JUSTIFICATION } from 'constants/answerTypes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { extractOpsOnlyText } from 'utils';

import { IDLE, SAVING, SUCCESS } from '../../exercise-header/ExerciseSavingSpinner/states';
import Input from 'components/common/Input';
import RichText from 'components/common/rich-text/RichText';

import useStyles from './styles';

const ExerciseJustification = ({ exerciseId, sectionId, num, justification, setSavingState, setSavingAnswer, answerable, color, mathSymbols, enableMathSymbols, setWriting, setPendingAnswer, hasWordCount, footer, onSelectionChange, onAnswer, commentBox }) => {
  const isDark = useSelector(state => state.page.isDark);
  const classes = useStyles();
  const timeoutRef = useRef();
  const attemptId = useSelector(selectAttemptId);
  const quillAnswersToggle = useFeature(toggles.quillAnswers);
  const loggedIn = useSelector(selectUserLoggedIn);
  const [answerJustificationRequest] = useApi(api.answerJustification, !loggedIn);
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const asyncAnswersToggle = useFeature(toggles.asyncAnswers);
  const wordCountToggle = useFeature(toggles.wordCount);

  const words = useMemo(() => {
    const value = justification?.ops;
    const regex = /[^A-Za-z0-9'-]/;

    const extractedWords = extractOpsOnlyText(value).split(regex);
    const filteredWords = extractedWords?.filter(word => word !== '');
    const wordCount = filteredWords?.length ?? 0;

    return wordCount;
  }, [justification?.ops]);

  const answerJustification = (newJustification) => {
    if (onSelectionChange) {
      onAnswer(newJustification);
      return;
    }

    if (asyncAnswersToggle) {
      setPendingAnswer(exerciseId, sectionId, {
        justification: newJustification,
        createdAt: Date.now(),
        type: JUSTIFICATION,
      });
      return;
    }

    setSavingAnswer(true);
    setSavingState(SAVING);

    answerJustificationRequest([attemptId, exerciseId], { justification: newJustification }, ({ data }) => {
      setSavingAnswer(false);
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          dispatch(updateJustification(exerciseId, newJustification, sectionId));
          return;
        } else if (data.status === 2 || data.status === 3) {
          toast.warning(lang.test.finishTestEnded);
          history.push('/');
        } else {
          toast.warning(lang.test.answerError(num));
        }
        setSavingState(IDLE);
      }, 300);
    });
  };

  const onInput = (event) => {
    const value = event.target?.value ?? event;

    if (asyncAnswersToggle) {
      answerJustification(value);
      return;
    }

    setWriting(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setWriting(false);
      answerJustification(value);
    }, 2000);
  };

  const onBlur = (event) => {
    if (asyncAnswersToggle) {
      return;
    }

    setWriting(false);
    const value = event.target?.value ?? event;

    clearTimeout(timeoutRef.current);

    answerJustification(value);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.justificationLabel}>
        {lang.test.justificationLabel}
      </div>
      {!quillAnswersToggle && (
        <InputArea
          id={`${exerciseId}-justification-input`}
          dark={isDark}
          placeholder={lang.test.justificationPlaceholder}
          defaultValue={justification}
          onInput={onInput}
          onBlur={onBlur}
          disabled={!answerable}
          maxLength={20000}
          className={cx(classes.inputArea, { color })}
        />
      )}
      {quillAnswersToggle && (
        <>
          {answerable && (
            <Input
              type="richtext"
              placeholder={lang.test.justificationPlaceholder}
              value={justification ?? {}}
              onChange={onInput}
              onBlur={(_range, _source, editor) => onBlur(editor.getContents())}
              className={classes.richText}
              mathSymbols={mathSymbols}
              enableMathSymbols={enableMathSymbols}
            />
          )}
          {!answerable && (
            <div className={classes.richTextContainer}>
              <RichText
                placeholder={lang.test.justificationPlaceholder}
                value={justification ?? {}}
                onChange={(_content, _delta, _source, editor) => onInput(editor.getContents())}
                className={cx(classes.richText, { disabled: !answerable })}
                enableMathSymbols={enableMathSymbols}
                footer={footer}
                onSelectionChange={onSelectionChange}
                disabled
                noHeader
              />
              {commentBox && (
                <div className={classes.commentBox}>
                  {commentBox}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {hasWordCount && wordCountToggle && (
        <div className={classes.wordCount}>
          {`${lang.words}: ${words}`}
        </div>
      )}
    </div>
  );
};

ExerciseJustification.propTypes = {
  exerciseId: PropTypes.string,
  sectionId: PropTypes.string,
  num: PropTypes.number,
  justification: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  setSavingState: PropTypes.func,
  setSavingAnswer: PropTypes.func,
  answerable: PropTypes.bool,
  color: PropTypes.bool,
  mathSymbols: PropTypes.object,
  enableMathSymbols: PropTypes.bool,
  setWriting: PropTypes.func,
  setPendingAnswer: PropTypes.func,
  hasWordCount: PropTypes.bool,
  footer: PropTypes.element,
  onAnswer: PropTypes.func,
  onSelectionChange: PropTypes.func,
  commentBox: PropTypes.element,
};

export default ExerciseJustification;
