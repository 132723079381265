import React, { useRef, useCallback, useState } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import toggles from 'toggles.js';

import { selectUserIsAdmin } from 'actions/userActions.js';
import { DRAG_DROP, DROPDOWN, WRITE } from 'constants/exerciseOptions.js';
import useFeature from 'hooks/useFeature.js';
import { renderGap } from 'utils/index.js';

import Input from 'components/common/Input/index.js';
import QuillRenderer from 'components/common/QuillRenderer/index.js';
import MiniRichText from 'components/common/rich-text/MiniRichText/index.js';
import Drop from 'components/exercises/exercise/exercise-answer/exercise-answer-filling/Drop/index.js';

import useStyles from './styles.js';

const ProcessNodeOptions = ({ id, gaps, drop, option, item, dropAnswers, setDropAnswers, onAnswer, answerable, correction, preview, enableMathSymbols, mathSymbols, setWriting }) => {
  const classes = useStyles();
  const timeoutRef = useRef();
  const identifiersToggle = useFeature(toggles.exportIdentifiers);
  const correctionItem = gaps.find(gap => gap.isCorrect && gap.position === drop.position);
  const isCorrectWrite = JSON.parse(drop?.text)?.length < 2 && correctionItem?.text ? JSON.parse(drop.text)[0].insert.trim() === JSON.parse(correctionItem.text).insert : false;
  const asyncAnswersToggle = useFeature(toggles.asyncAnswers);
  const isAdmin = useSelector(selectUserIsAdmin);

  const dropValue = drop && drop.text
    ? {
      ops: JSON.parse(drop.text).length
        ? JSON.parse(drop.text)
        : (JSON.parse(drop.text).formula
          ? [{ insert: JSON.parse(drop.text) }]
          : [JSON.parse(drop.text)]),
    }
    : {
      ops: [],
    };

  const [writingAnswer, setWritingAnswer] = useState(dropValue);

  const getOptions = () => {
    return gaps.filter(el => el.position === drop.position).sort((a, b) => a.order - b.order).map(gap => ({
      value: gap.id,
      label: (
        <div className={classes.optionWrapper}>
          <div dangerouslySetInnerHTML={{ __html: renderGap(gap) }} />
          {!answerable && gap.identifier && identifiersToggle && (
            <div className={classes.identifier}>
              (
              {gap.identifier}
              )
            </div>
          )}
          {gap.isCorrect && !isAdmin && (
            <FontAwesomeIcon
              className={classes.check}
              icon={faCheck}
            />
          )}
        </div>
      ),
      isDisabled: !answerable,
    }));
  };

  const onChange = useCallback((event) => {
    const newDrop = dropAnswers.find(gap => gap.id === drop.id);

    newDrop.gapId = event.value;

    setDropAnswers(dropAnswers);
    onAnswer(dropAnswers);
  }, [dropAnswers, onAnswer, drop, setDropAnswers]);

  const onChangeWrite = useCallback((event) => {
    const newDropAnswers = dropAnswers.map(gap => {
      if (gap.id === drop.id) {
        return {
          ...gap,
          text: JSON.stringify(event.ops),
        };
      }

      return gap;
    });

    setWritingAnswer(event);

    setDropAnswers(newDropAnswers);

    if (asyncAnswersToggle) {
      onAnswer(newDropAnswers);
      return;
    }

    setWriting(true);

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      onAnswer(newDropAnswers);
      setWriting(false);
    }, 2000);
  }, [dropAnswers, onAnswer, drop, setDropAnswers, setWriting, asyncAnswersToggle]);

  const onBlur = useCallback((event) => {
    if (asyncAnswersToggle) {
      return;
    }

    setWriting(false);
    const newDrop = dropAnswers.find(gap => gap.id === drop.id);

    newDrop.text = JSON.stringify(event.ops);

    clearTimeout(timeoutRef.current);

    setDropAnswers(dropAnswers);
    onAnswer(dropAnswers);
  }, [asyncAnswersToggle, setWriting, dropAnswers, setDropAnswers, onAnswer, drop.id]);

  return (
    <>
      {(option === null || option === DRAG_DROP) && (
        <Drop
          answerId={id}
          drop={drop}
          item={preview ? { ...drop, isCorrectAnswer: true } : item}
          correctionItem={correctionItem}
          correction={correction || preview}
          answerable={answerable}
        />
      )}
      {option === DROPDOWN && (
        <Input
          type="select"
          placeholder={
            correction ?
              <Drop
                answerId={id}
                drop={drop}
                item={item}
                correctionItem={correctionItem}
                correction
              />
              :
              <div dangerouslySetInnerHTML={{ __html: renderGap(drop.text ? drop : item) }} />
          }
          options={getOptions()}
          className={cx(classes.inputDropdown, { isCorrect: !isAdmin && !answerable && (item?.isCorrectAnswer || drop?.isCorrect), answerable: answerable, correction: correction, isAdmin })}
          onChange={onChange}
        />
      )}
      {option === WRITE && !correction && !answerable && (
        <QuillRenderer
          value={answerable ? writingAnswer : dropValue}
          className={classes.previewWrite}
        />
      )}
      {option === WRITE && !correction && answerable && (
        <MiniRichText
          key={id}
          value={answerable ? writingAnswer : dropValue}
          className={cx(classes.inputWrite, { isCorrect: preview || (!answerable && isCorrectWrite), answerable: answerable, isAdmin })}
          onChange={(_range, _delta, _source, editor) => onChangeWrite(editor.getContents())}
          onBlur={(_range, _source, editor) => onBlur(editor.getContents())}
          disabled={!answerable}
          mathSymbols={mathSymbols}
          enableMathSymbols={enableMathSymbols}
        />
      )}
      {option === WRITE && correction && (
        <Drop
          answerId={id}
          drop={drop}
          item={drop}
          correctionItem={correctionItem}
          correction
        />
      )}
    </>
  );
};

ProcessNodeOptions.propTypes = {
  id: PropTypes.string,
  gaps: PropTypes.arrayOf(PropTypes.object),
  drop: PropTypes.object,
  option: PropTypes.string,
  item: PropTypes.object,
  dropAnswers: PropTypes.object,
  setDropAnswers: PropTypes.func,
  onAnswer: PropTypes.func,
  answerable: PropTypes.bool,
  correction: PropTypes.bool,
  preview: PropTypes.bool,
  enableMathSymbols: PropTypes.bool,
  mathSymbols: PropTypes.object,
  setWriting: PropTypes.func,
};

export default ProcessNodeOptions;
