import React, { useCallback, useMemo, useRef, useState } from 'react';
import { faSquareRootAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Quill from 'quill';

import lang from 'lang';

import FormulaModal from '../FormulaModal';
import ReactQuill from '../ReactQuill';
import Tooltip from 'components/common/Tooltip';

import useStyles from './styles';

const MiniRichText = ({ value, placeholder, mathSymbols, enableMathSymbols, onChange, onBlur, className, disabled, autoComplete }) => {
  const classes = useStyles();
  const ref = useRef();

  const [showFormulaModal, setShowFormulaModal] = useState(false);

  const addFormula = useCallback((value) => {
    if (ref.current) {
      const quill = ref.current.getEditor();
      const range = quill.getSelection(true);

      quill.insertEmbed(range.index, 'formula', { value, mathSymbols: JSON.stringify(mathSymbols) }, Quill.sources.USER);
      quill.insertText(range.index + 1, ' ', Quill.sources.USER);
      quill.setSelection(range.index + 2);
    }
  }, [ref, mathSymbols]);

  const formulaHandler = useCallback(() => {
    const quill = ref.current.getEditor();
    quill.getSelection(true);
    setShowFormulaModal(true);
  }, [ref]);

  const memoizedModals = useMemo(() => {
    return (
      <FormulaModal
        open={showFormulaModal}
        close={() => setShowFormulaModal(false)}
        insert={addFormula}
        mathSymbols={mathSymbols}
        enableMathSymbols={enableMathSymbols}
      />
    );
  }
  , [showFormulaModal, setShowFormulaModal, addFormula, mathSymbols, enableMathSymbols]);

  return (
    <div
      className={cx(classes.quillContainer, className)}
      onDrop={(e) => e.preventDefault()}
    >
      {memoizedModals}
      <ReactQuill
        ref={ref}
        value={value}
        defaultValue={value}
        theme={'bubble'}
        readOnly={disabled}
        onChange={disabled ? null : onChange}
        onBlur={disabled ? null : onBlur}
        placeholder={placeholder}
        autoComplete={autoComplete}
        modules={{
          keyboard: {
            bindings: {
              'list autofill': {
                prefix: /(\*|\[ ?\]|\[x\])$/,
              },
            },
          },
        }}
        style={{
          width: '100%',
          backgroundColor: 'white',
          color: 'black',
        }}
        formats={['formula']}
      />
      <div className={classes.formulaBtnContainer}>
        <Tooltip
          tip={lang.exerciseForm.formulaHeader}
          place="right"
        >
          <FontAwesomeIcon
            icon={faSquareRootAlt}
            className={cx(classes.formulaBtn, { disabled })}
            onClick={formulaHandler}
          />
        </Tooltip>
      </div>
    </div>
  );
};

MiniRichText.propTypes = {
  value: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enableMathSymbols: PropTypes.bool,
  mathSymbols: PropTypes.object,
  autoComplete: PropTypes.string,
};

export default MiniRichText;
